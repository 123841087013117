<template>
    <div v-if="ready" class="fill-height">
        <subpage-top-bar>
            <template #default>
                Трансфер ЦОК: экспорт
            </template>
            <template #right>
                <v-btn
                    dark
                    class="mr-2"
                    outlined
                    @click="toggleIsMergedDECVisibility"
                    title="Отображение 'входящих в состав' ЦОК"
                >
                    <span class="mr-1">{{showIsMergedDEC ? 'Вкл' : 'Выкл'}}</span>
                    <v-divider vertical class="mx-2" />
                    <v-icon>mdi-network-outline</v-icon>
                </v-btn>
            </template>
        </subpage-top-bar>
       
        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                v-if="!errors"
                justify="start"
                align="stretch"
                class="fill-height overflow-hidden"
            >
                <v-col :cols="12" class="fill-height overflow-auto">
                    <v-stepper
                        v-model="transferExportOptions.step"
                        vertical
                        elevation="0"
                    >
                        <v-stepper-step
                            step="1"
                            :complete="transferExportOptions.step > 1"
                        >
                            Укажите ЦОК для переноса
                        </v-stepper-step>
                        <div class="v-stepper__content" :class="{unavailable: transferExportOptions.step > 1}">
                            <v-data-table
                                :headers="headers"
                                :items="filteredItems"
                                hide-default-footer
                                :items-per-page="99999"
                                class="elevation-1"
                            >
                                <template v-slot:[`item.version`]="{ item }">
                                    {{ versionsFormatted[item.version] }}
                                </template>

                                <template v-slot:[`item.merge`]="{ item }">
                                    <v-icon v-if="item.merge">mdi-merge</v-icon>
                                </template>

                                <template v-slot:[`item.isMerged`]="{ item }">
                                    <v-icon v-if="item.isMerged">mdi-network-outline</v-icon>
                                </template>

                                <template v-slot:[`item.selected`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.selected"
                                    ></v-simple-checkbox>
                                </template>
                            </v-data-table>
                            <v-divider />
                            <v-btn
                                v-if="transferExportOptions.step === 1"
                                color="primary"
                                :disabled="!allowExport"
                                @click.prevent.stop="transferExport"
                            >
                                Начать
                            </v-btn>
                        </div>

                        <v-stepper-step
                            step="2"
                            :complete="transferExportOptions.step > 2"
                        >
                            Ожидайте окончания процесса
                            <small>Можно покинкуть страницу и вернуться позже.</small>
                        </v-stepper-step>
                        <div class="v-stepper__content" v-if="transferExportOptions.step === 2">
                            <v-progress-linear
                                color="primary"
                                buffer-value="0"
                                :value="progressPercent"
                                stream
                            ></v-progress-linear>
                            <br />
                            <div class="text-caption"><strong>Выполнено:</strong> {{ transferExportOptions.progress.complete }} из {{ transferExportOptions.progress.total }}</div>
                            <br />
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        Лог
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <pre>{{ _.has(transferExportOptions, 'log') ? transferExportOptions.log : 'Пока нет данных логгирования.' }}</pre>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                        
                        <v-stepper-step
                            :complete="transferExportOptions.step > 3"
                            step="3"
                        >
                            Готово!
                        </v-stepper-step>
                        <div class="v-stepper__content" v-if="transferExportOptions.step === 3">
                            <v-alert v-if="_.has(transferExportOptions, 'error')" type="error">
                                <p><strong>Экспорт материалов для переноса не выполнен.</strong></p>
                                <p><strong>Ошибка:</strong> {{ transferExportOptions.error }}</p>
                            </v-alert>

                            <v-alert v-if="!_.has(transferExportOptions, 'error')" type="success">
                                <p><strong>Экспорт материалов для переноса успешно выполнен!</strong></p>
                                <p>Ссылка на архив: <v-btn link dark color="primary" :href="transferExportOptions.file" target="_blank">Скачать</v-btn></p>
                            </v-alert>

                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        Лог
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <pre>{{ _.has(transferExportOptions, 'log') ? transferExportOptions.log : 'Пока нет данных логгирования.' }}</pre>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <v-btn
                                color="primary"
                                @click.prevent.stop="reset"
                            >
                                Начать новую операцию
                            </v-btn>
                        </div>

                    </v-stepper>
                </v-col>
            </v-row>

            <div v-else class="mx-auto">
                <v-alert type="error">
                    {{ errors || 'Произошла ошибка на сервере. Обратитесь за помощью к администратору.' }}
                </v-alert>

                <div
                    v-if="allowEdit"
                    class="w-100 d-flex justify-center align-center"
                >
                    <v-btn class="mx-auto" @click="resetError">
                        <v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
                        Отмена
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
    <!-- Loading icon -->
    <div
        v-else
        class="
            wv-100
            vh-100
            fill-height
            d-flex
            flex-row
            justify-center
            align-center
        "
    >
        <v-progress-circular
            :size="70"
            :width="7"
            color="purple darken-3"
            indeterminate
        />
    </div>
</template>
<script>
import SubpageTopBar from '@/components/template/SubpageTopBar';
import { mapGetters } from "vuex";
const PROGRESS_CHECK_INTERVAL = 10000;

export default {
    name: "TransferExport",
    components: {
        SubpageTopBar
    },
    data () {
        return {
            ready: false,
            headers: [
                { text: "ЦОК", value: "courseName", sortable: false },
                { text: "Вариант контента", value: "version", width: 1, align: "center", sortable: false },
                { text: "Объединённый", value: "merge", width: 1, align: "center", sortable: false },
                { text: "В составе", value: "isMerged", width: '100px', align: "center", sortable: false },
                { text: "", value: "selected", width: 1, align: "center", sortable: false }
            ],
            errors: null,
            showIsMergedDEC: false,
            transferExportOptions: {
                step: 1,
                items: [],
                progress: {
                    total: 0,
                    complete: 0
                }
            }
        };
    },
    computed: {
        ...mapGetters("app", ["eduCourseModelVersionList"]),
        ...mapGetters("user", ["allowEdit"]),
        allowExport() {
            return _.size(this.getSelectedItems()) > 0;
        },
        progressPercent() {
            return this.transferExportOptions.progress.total > 0 ? Math.ceil((this.transferExportOptions.progress.complete / this.transferExportOptions.progress.total) * 100) : 0;
        },
        mergedItems () {
            const items = this.$store.state.app?.eduCourses || []
            let itemsToHide = [];
            items?.map(_item => {
                if (_item.merge?.length) {
                    itemsToHide = itemsToHide.concat(_item.merge);
                }
            })
            return itemsToHide
        },
        filteredItems () {
            if (this.showIsMergedDEC)
                return this.transferExportOptions.items
            else
                return this.transferExportOptions.items.filter(item => !item.isMerged)
        },
        versionsFormatted() {
            return _.zipObject(_.map(this.eduCourseModelVersionList, 'value'), _.map(this.eduCourseModelVersionList, 'text'));
        }
    },
    methods: {
        async transferExport() {
            // Set options
            this.transferExportOptions.step = 2;
            this.transferExportOptions.items = this.getSelectedItems();
            this.transferExportOptions.progress.total = _.size(this.transferExportOptions.items);
            // Init export process
            await this.$store.dispatch('transfer/export', this.transferExportOptions);
            // Start status check daemon
            const progressChecker = setInterval(async () => {
                const { data } = await this.$store.dispatch('transfer/exportCheck');
                this.$set(this, 'transferExportOptions', data.options);
                if(this.transferExportOptions.step > 2)
                    clearInterval(progressChecker);
            }, PROGRESS_CHECK_INTERVAL)
        },
        reset() {
            this.transferExportOptions = {
                step: 1,
                items: this.getAllItems(),
                progress: {
                    total: 0,
                    complete: 0
                }
            }
        },
        async updateTransferExportOptions() {
            const { data } = await this.$store.dispatch('transfer/exportCheck');
            if(!_.isNil(data))
                this.$set(this, 'transferExportOptions', data.options);
        },
        createProgressChecker() {
            const progressChecker = setInterval(async () => {
                await this.updateTransferExportOptions();
                if(this.transferExportOptions.step > 2)
                    clearInterval(progressChecker);
            }, PROGRESS_CHECK_INTERVAL);
        },
        getAllItems() {
            return _.map(_.filter(this.$store.state.app.eduCourses, {courseTypeId: 0}), o =>{
                return {
                    id: o.id,
                    externalId: o.externalId,
                    courseName: o.courseName,
                    isMerged: this.mergedItems.includes(o.externalId),
                    merge: !!o.merge,
                    version: o.version,
                    selected: false
                }
            });
        },
        getSelectedItems() {
            return  _.filter(this.transferExportOptions.items, o => {
                return o.selected === true;
            });
        },
        resetError () {
            this.errors = null;
        },
        toggleIsMergedDECVisibility () {
            this.showIsMergedDEC = !this.showIsMergedDEC
        }
    },
    async created () {
        try {
            if (!this.allowEdit) {
                throw new Error('У вас недостаточно прав для доступа к этому разделу.');
            }
            // Fetch data 
            await this.$store.dispatch('app/fetch', {
                filter: ['=', 'courseTypeId', 0],
                fields: 'id,version,externalId,courseName,courseTypeId,merge'
            });
            this.transferExportOptions.items = this.getAllItems();

            // Fetch last transfer export
            await this.updateTransferExportOptions();        
            if(this.transferExportOptions.step === 2)
                this.createProgressChecker();

        } catch (e) {
            console.error(e);
            this.errors = e.message;
        } finally {
            this.ready = true;
        }
    }
};
</script>